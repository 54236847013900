














import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OReportGenerate: () =>
      import("@/components/organisms/event/report/o-report-generate.vue"),
    OReportSiemens: () =>
      import("@/components/organisms/event/report/o-report-siemens.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      eventType: "",
    });

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventType = event.type;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    return { state };
  },
});
